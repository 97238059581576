body {
  background: #f7f7f7;
  font-family: Arial, Helvetica, sans-serif;
}

main {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  padding-right: 15em;
  
  align-items: flex-end;
  align-content: flex-start;
  justify-content: center;
}

h1, h2, h3, span {
  margin: 0;
  width: 100%;
  text-align: right;
  flex: 1 0 auto;
  /* padding-right: 15vw; */
}

h2 {
  font-size: large;
  color: #AA3731;
  margin-bottom: 1.5em;
}

h1 {
  font-size: x-large;
}

.link-list {
  margin-top: 1.5em;
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 auto;
}

.link-item {
  flex: 1 0 auto;
}

p.closeBracket {
  font-family: monospace;
  color: #AA3731;
  display: block;
  position: absolute;
  bottom: 0%;
  left: 50%;
}